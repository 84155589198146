import styles from './Index.module.scss';
import { MainPageDataType, SettingsType } from '@/types';
import { BannerSlider } from '@/components/BannerSlider';
import { InferGetServerSidePropsType } from 'next';
import { fetchData } from '@/services/fetchData';
import { AdsBlock } from '@/components/AdsBlock';
import { MobileEffect, SliderBlock } from '@/components/SliderBlock';
import { ButtonType, CardSize, CardType, TagPosition } from '@/components/Card';
import { Layout } from '@/layout';
import { PROMENAD_CODE } from '@/constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectSuccessReg, setSuccessReg } from '@/store';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const Home = ({
  menu,
  content,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const successRegistration = useSelector(selectSuccessReg);
  const dispatch = useDispatch();

  useEffect(() => {
    if (successRegistration) {
      toast.success(
        'Письмо для подтверждения регистрации отправлено на указаннную почту',
      );
      dispatch(setSuccessReg(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successRegistration]);

  const backendTitle = { __html: content.meta.h1 || 'Остров мечты' };

  return (
    <Layout data={menu} meta={content.meta}>
      <main id="@dapanov">
        <h1 className={styles.heading} dangerouslySetInnerHTML={backendTitle} />
        <BannerSlider banners={content.banners} />
        <SliderBlock
          items={content.objects}
          title="Наши объекты"
          cardType={CardType.Filled}
          button="Все объекты"
          link="/objects"
          size={CardSize.XL}
          tagPosition={TagPosition.Bottom}
          mobileEffect={MobileEffect.Cards}
        />
        <SliderBlock
          items={content.entertainments}
          cardType={CardType.Filled}
          button="Все фишки"
          link="/entertainments"
          size={CardSize.M}
          title="Наши фишки"
          buttonType={ButtonType.Arrow}
          tagPosition={TagPosition.Top}
          mobileEffect={MobileEffect.Slider}
        />
        <SliderBlock
          items={content.events}
          cardType={CardType.Panel}
          button="Вся афиша"
          link="/events"
          size={CardSize.S}
          tagPosition={TagPosition.Top}
          title="Афиша мероприятий"
          mobileEffect={MobileEffect.Slider}
        />
        <AdsBlock ads={content.ads} />
        <SliderBlock
          items={content.trades}
          cardType={CardType.Panel}
          button="Все магазины и&nbsp;рестораны"
          link={`/objects/${PROMENAD_CODE}`}
          size={CardSize.XS}
          title="Чем ещё заняться?"
          mobileEffect={MobileEffect.Slider}
        />
        <SliderBlock
          items={content.news}
          cardType={CardType.Panel}
          button="Все новости"
          link="/news"
          size={CardSize.S}
          title="Новости"
          mobileEffect={MobileEffect.Slider}
          withYear
        />
        <div className={styles.mobileAds}>
          <AdsBlock ads={content.ads} />
        </div>
        <Toaster
          toastOptions={{
            error: {
              duration: 5000,
            },
            success: {
              duration: 5000,
            },
          }}
        />
      </main>
    </Layout>
  );
};

export async function getServerSideProps() {
  const content: MainPageDataType = await fetchData({
    url: `/main/view`,
  });

  const menu: SettingsType = await fetchData({
    url: `/settings/view`,
  });

  if (!content) return { notFound: true };

  return { props: { content, menu } };
}

export default Home;
